import React from "react"
import { Link } from "gatsby"
import NewsHeader from "../../components/blog/blogHeader"
import NewsFooter from "../../components/blog/blogFooter"
import Footer from "../../components/footer"
import SEO from "../../components/seo"

import arrow from "../../../assets/images/servicesPages/arrow-right-min.png"

export default () => 
<div>
    <SEO title={'Top 20 For PHP Development | ICON Worldwide'} 
    description="According to the official 2019 software development company rankings, we rank number 20 for PHP development: read more!"
    canonical={'https://icon-worldwide.com/blog/number-20-for-php-development'}/>

    <NewsHeader/>

    <div id="single-news">
        <h1>ICON Worldwide ranks <span>number 20</span> for PHP development</h1>
        <div id="title-underline"></div>
        <div id="single-news-texts">
            <p><a  rel="noopener noreferrer" target="_blank" rel="noopener noreferrer" href="https://www.softwaredevelopmentcompany.co/blog/top-10-open-source-software-examples-of-2019/">Top Custom Software Development Company</a></p>
            <p>According to the official 2019 software development company rankings, ICON Worldwide ranks number 20 for <a  rel="noopener noreferrer" target="_blank" rel="noopener noreferrer" href="https://www.softwaredevelopmentcompany.co/php-development-companies/">PHP development</a>.</p>
            <p>We are very proud of this achievement, and would like to thank and congratulate our teams for their commitment and hard work.</p>
        </div>

        <img src={arrow} id="single-news-horizontal-arrow" alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>
        <h4><Link to="/contact-us" className="cta cta-long">Let's start a new project together</Link></h4>

        <NewsFooter previous="software-development-life-cycle-steps" next="top-20-for-healthcare-software"/>
    </div>

    <Footer noScrollbar="true"/>
</div>